import React, { useEffect } from 'react'
import gsap from 'gsap'

// Components
import Scroll from '../../components/locomotiveScroll'
import Header from '../../components/Header'
// import Cursor from '../../components/Cursor'

// Global styles
import '../../assets/scss/style.scss'

const Layout = ({ children, location }) => {
  useEffect(() => {
    // Set background color when no preloader animations
    gsap.to('html, body', {
      css: {
        visibility: 'visible',
        backgroundColor: '#0D0D0D',
      },
      duration: 0,
    })
  }, [])

  return (
    <>
      <Scroll callbacks={location.pathname} />
      <Header {...location} />
      {/* <Cursor /> */}
      <div id="wrapper">
        <main id="main" role="main">
          {children}
        </main>
      </div>
    </>
  )
}

export default Layout
