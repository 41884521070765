import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link, navigate } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'

import { Logo, Dribbble, Twitter, Linkedin } from '../Icons'
import './style.scss'

const Header = location => {
  const [isActive, setIsActive] = useState(false)

  const handleMenu = e => {
    e.preventDefault()
    const target = e.target.getAttribute('href')

    setIsActive(!isActive)
    setTimeout(() => {
      navigate(target)
    }, 1200)
  }

  const menuVariants = {
    initial: {
      opacity: 0,
      transition: {
        staggerChildren: 0.05,
        type: 'tween',
        ease: 'easeInOut',
      },
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
        type: 'tween',
        ease: 'easeInOut',
        when: 'beforeChildren',
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 1,
        staggerChildren: 0.1,
        type: 'tween',
        ease: 'easeInOut',
        staggerDirection: -1,
        when: 'afterChildren',
      },
    },
  }

  const itemVariants = {
    initial: {
      y: 20,
      opacity: 0,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
    exit: {
      y: 20,
      opacity: 0,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  }

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
              social {
                title
                link
              }
            }
          }
          allMarkdownRemark(sort: { order: ASC, fields: id }) {
            edges {
              node {
                id
                frontmatter {
                  title
                  slug
                }
              }
            }
          }
        }
      `}
      render={({ site, allMarkdownRemark }) => (
        <>
          <header
            className={`mrd-header`}
            data-scroll-target="#___gatsby"
            data-scroll
            data-scroll-sticky
          >
            <Container fluid>
              <Row className="justify-content-between align-items-center">
                <Col xs="auto">
                  <h1>
                    <Link to="/" onClick={() => setIsActive(false)}>
                      {location.pathname.split('/')[1] === 'works' ? (
                        <span>Retour</span>
                      ) : (
                        <div className="mrd-header__brand">
                          <Logo />
                        </div>
                      )}
                    </Link>
                  </h1>
                </Col>
                <Col xs="auto" xl="auto">
                  <motion.button
                    onClick={() => setIsActive(!isActive)}
                    className={`mrd-header__burger ${isActive ? 'active' : ''}`}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </motion.button>
                </Col>
              </Row>
            </Container>
          </header>

          <AnimatePresence>
            {isActive && (
              <motion.nav
                initial="initial"
                animate="visible"
                exit="exit"
                variants={menuVariants}
                className={`mrd-menu`}
              >
                <Container>
                  <Row className="justify-content-center row-reverse">
                    <Col xs={12} xl={{ span: 6 }}>
                      <motion.div variants={itemVariants}>
                        <Link
                          to={`/`}
                          onClick={e => handleMenu(e)}
                          className="mrd-menu__item"
                          activeClassName="active"
                        >
                          Projets
                        </Link>
                      </motion.div>
                      <motion.div variants={itemVariants}>
                        <Link
                          to="/about"
                          onClick={e => handleMenu(e)}
                          className="mrd-menu__item"
                          activeClassName="active"
                        >
                          A propos
                        </Link>
                      </motion.div>
                      <motion.div variants={itemVariants}>
                        <a
                          href="mailto:charlesabedie@gmail.com"
                          className="mrd-menu__item"
                        >
                          Contact
                        </a>
                      </motion.div>

                      <motion.div
                        className="mrd-menu__rs"
                        variants={itemVariants}
                      >
                        <motion.p variants={itemVariants}>Follow me</motion.p>
                        <a
                          href="https://dribbble.com/CharlesAbedie"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Dribbble color="#fff" />
                        </a>
                        <a
                          href="https://twitter.com/AbedieCharles"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Twitter color="#fff" />
                        </a>
                        <a
                          href="https://www.linkedin.com/in/charles-abedie-521abb66/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Linkedin color="#fff" />
                        </a>
                      </motion.div>
                    </Col>
                  </Row>
                </Container>
              </motion.nav>
            )}
          </AnimatePresence>
        </>
      )}
    />
  )
}

export default Header
