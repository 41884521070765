export const scroll = {
  // Locomotive Scroll
  // https://github.com/locomotivemtl/locomotive-scroll#instance-options
  container: '#wrapper',
  options: {
    smooth: true,
    smoothMobile: true,
    getDirection: true,
    lerp: 0.05,
  },
}
